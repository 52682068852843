import React from "react"
import styles from "./projeler.module.scss"
import Menubar from "../components/menubar/menubar"
import { useStaticQuery, graphql } from "gatsby"
import ProjectFoursome from "../components/productFoursome/productFoursome"
import Footer from "../components/footer/footer"
import ProjectTopImage from "../img/proje_975.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons"

const Projeler = () => {
  const data = useStaticQuery(graphql`
    query {
      project1: file(relativePath: { eq: "nyKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project2: file(relativePath: { eq: "kirklareliKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project3: file(relativePath: { eq: "onVillaKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project4: file(relativePath: { eq: "syKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project5: file(relativePath: { eq: "yesilyurtOfisKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project6: file(relativePath: { eq: "mbKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project7: file(relativePath: { eq: "myKonutuKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project8: file(relativePath: { eq: "yesilyurtDCKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project9: file(relativePath: { eq: "taiTusasKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project10: file(relativePath: { eq: "demayofisKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project11: file(relativePath: { eq: "demirkilicKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      project12: file(relativePath: { eq: "oncuogluKapak.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)
  return (
    <div className={styles.projelerContainer}>
      <div className={styles.leftE}></div>
      <div className={styles.centerSite}>
        <Menubar />
        <div className={styles.imageTopProjelerContainer}>
          <img
            src={ProjectTopImage}
            alt="projects"
            className={styles.imageTopProjeler}
          />
          <div className={styles.verticalLineWhiteProjeler}>
            <div className={styles.socialMediaIcons}>
              <a
                href="https://www.facebook.com/atiaahsap"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="1x"
                  className={styles.ffIcon}
                />
              </a>
              <br />
              <a
                href="https://www.instagram.com/atiaahsap/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="1x"
                  className={styles.igIcon}
                />
              </a>
            </div>
          </div>
        </div>
        <ProjectFoursome
          /* Product 1-1 */
          iSrc1={data.project1.childImageSharp.fluid.src}
          info1={`NY \nKONUTU`}
          iLink1={`/projeler/nyKonutuPD`}
          /* Product 1-2 */
          iSrc2={data.project2.childImageSharp.fluid.src}
          info2={`KIRKLARELİ VALİLİĞİ \nTOPLANTI SALONU`}
          iLink2={`/projeler/kirklareliValilik`}
          /* Product 1-3 */
          iSrc3={data.project3.childImageSharp.fluid.src}
          info3={`DATÇA - BURGAZ \nVİLLALARI`}
          iLink3={`projeler/datcaBurgazVilla`}
          /* Product 1-4 */
          iSrc4={data.project4.childImageSharp.fluid.src}
          info4={`SY \nKONUTU`}
          iLink4={`/projeler/syKonutu`}
        />
        <ProjectFoursome
          /* Product 2-1 */
          iSrc1={data.project5.childImageSharp.fluid.src}
          info1={`YEŞİLYURT ENERJİ \nOFİS`}
          iLink1={`/projeler/yesilyurtOfis`}
          /* Product 2-2 */
          iSrc2={data.project6.childImageSharp.fluid.src}
          info2={`MB \nKONUTU`}
          iLink2={`/projeler/mbKonutu`}
          /* Product 2-3 */
          iSrc3={data.project7.childImageSharp.fluid.src}
          info3={`MY \nKONUTU`}
          iLink3={`/projeler/myKonutu`}
          /* Product 2-4 */
          iSrc4={data.project8.childImageSharp.fluid.src}
          info4={`YEŞİLYURT DEMİR ÇELİK \nYÖNETİM BİNASI`}
          iLink4={`/projeler/yesilyurtDC`}
        />
        <ProjectFoursome
          /* Product 3-1 */
          iSrc1={data.project9.childImageSharp.fluid.src}
          info1={` TAİ & TUSAŞ \nİK BİNASI`}
          iLink1={`/projeler/taiTusas`}
          /* Product 3-2 */
          iSrc2={data.project10.childImageSharp.fluid.src}
          info2={`D'ARCHITECTs DEMAY \nMİMARLIK OFİS`}
          iLink2={`/projeler/demayOfis`}
          /* Product 3-3 */
          iSrc3={data.project11.childImageSharp.fluid.src}
          info3={`DEMİRKILIÇ \nKONUTU`}
          iLink3={`/projeler/demirkilicKonutu`}
          /* Product 3-4 */
          iSrc4={data.project12.childImageSharp.fluid.src}
          info4={`ÖNCÜOĞLU \nMİMARLIK OFİS`}
          iLink4={`/projeler/oncuogluOfis`}
        />
        <Footer />
      </div>
      <div className={styles.rightE}></div>
    </div>
  )
}

export default Projeler
